import useIdentity from '@/components/SiteIdentity'

export default defineNuxtRouteMiddleware(async (to) => {
  // Check if auth_token exists in query parameters

  console.log('ok', to.query.auth_token)
  if (to.query.auth_token) {
    const authToken = to.query.auth_token as string
    const siteIdentity = useIdentity()

    try {
      // Authenticate with the token
      await siteIdentity.authenticate(authToken)

      // Remove auth_token from query parameters
      let query = Object.assign({}, to.query)

      console.log('before', query)

      delete query.auth_token

      console.log('after', query)

      if (import.meta.client) {
        return navigateTo(to.path, {
          replace: true
        })
      }
    } catch (error) {
      console.error('Authentication failed:', error)
    }
  }
}) 