
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93mvebfMgEVhMeta } from "/opt/build/repo/pages/blog/[...slug].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexlWyU3b38IQMeta } from "/opt/build/repo/pages/legal/index.vue?macro=true";
import { default as indexZGHdGUcL1pMeta } from "/opt/build/repo/pages/rgpd/index.vue?macro=true";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93mvebfMgEVhMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[...slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    meta: indexlWyU3b38IQMeta || {},
    component: () => import("/opt/build/repo/pages/legal/index.vue")
  },
  {
    name: "rgpd",
    path: "/rgpd",
    meta: indexZGHdGUcL1pMeta || {},
    component: () => import("/opt/build/repo/pages/rgpd/index.vue")
  }
]